
import {Component, OnInit, OnDestroy, Output, EventEmitter, Input} from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../../services/main.service';
import {FormGroup, Validators, FormBuilder, FormControl} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {MatDialog} from "@angular/material";
import {MapLocationDialog} from "../../lib/map-location/map-location.dialog";
import { appConfig } from 'src/config';


declare var $: any;


@Component({
	selector: 'add-address',
	templateUrl: 'add-address.component.html',
	styleUrls: ['add-address.component.scss'],
})
export class AddAddress implements OnInit {


	addressForm: FormGroup;
	currentPage:  any = 1;
	perPage: number;
	index:  any = 1;
	totalPages: number;

	url:any;

	@Input() Item : any;
	@Input() user_id : any;
	@Output() isAddAddress  = new EventEmitter();
	Customers: any = [];


	constructor(private router: Router, private mainService: MainService, protected formbuilder: FormBuilder, protected dialog: MatDialog) {

		this.addressForm = this.formbuilder.group({
			person_name: [null],
			person_phone: [null, [Validators.maxLength(14), Validators.minLength(7)]],
			address: [null],
			latitude: [null],
			longitude: [null],
			user_id: [null],
		})
	}

	ngOnInit() 
	{
		// console.log(this.Item);
		// this.getCustomersList();
		this.addressForm.patchValue(this.Item);
		this.addressForm.get('user_id').setValue(this.user_id);
	}

	doAddressForm()
	{
		if(this.Item != '')
		{
			this.url = '/update-location';
			this.addressForm.addControl('id', new FormControl(this.Item.id));
		}
		else
		{
			this.url = '/add-location';
		}

		this.mainService.postData(appConfig.base_url_slug + this.url, this.addressForm.value).then(response => 
		{
			if (response.status == 200 || response.status === 201) 
			{
				this.addressForm.reset();
				this.isAddAddress.emit(false);
			}
			else 
			{

			}
		})
	}
	onBack()
	{
		this.isAddAddress.emit(false);
	}

	// getCustomersList(): void 
	// {
	// 	let url = '/view-users?page=' + 1 + '&per_page=' + this.perPage + '&role_type=partner&user_status=1';
	// 	this.mainService.getList(appConfig.base_url_slug + url).then(result => 
	// 	{
	// 		if (result.status === 200 && result.data) 
	// 		{
	// 			this.Customers = result.data;
	// 		}
	// 		else 
	// 		{
	// 			this.Customers = [];
	// 		}
	// 	});
	// }

	onSelectAddress(e)
	{
		let split = e.latlng.split(',');
		this.addressForm.get('address').setValue(e.formatted_address);
		this.addressForm.get('latitude').setValue(split[0]);
		this.addressForm.get('longitude').setValue(split[1]);
	}
}


