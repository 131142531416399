import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class MainAuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate() {
        let MTCAdmin = JSON.parse(localStorage.getItem('MTCAdmin'));
        if (MTCAdmin && MTCAdmin.auth_key != null) 
        {
            return true;
        }
        this.router.navigate(['/auth/login']);
        return false;
    }
}

