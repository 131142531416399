﻿import { Component, OnInit, EventEmitter, Input, Output, NgZone, ViewEncapsulation, OnDestroy, ElementRef  } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

import { GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';

declare var google: any;

@Component({
    templateUrl: './location_dialog.html',
    styleUrls: ['./location_picker.css'],
    encapsulation: ViewEncapsulation.None
})
export class LocationPickerDialog implements OnInit
{
    @Input()
    initialLocation: string;
    //: {lat: number, lng: number} = null;
    initialZoom: number = 10;
    currentLocation: {lat: number, lng: number};

    map: any;
    marker: any;
    formatted_address: string;

    constructor(private elRef:ElementRef, private _loader: MapsAPILoader,
        private _zone: NgZone,
        public dialogRef: MatDialogRef<LocationPickerDialog>,
        public gMaps: GoogleMapsAPIWrapper)
    {
        this.map = null;
        this.marker = null;
        this.formatted_address = null;
    }

    // ngAfterViewInit()
    // {
    //     this.elRef.nativeElement.parentElement.classList.add("mat-dialog-containerss");
    // }

    ngOnInit(): void
    {
        // let loc = {lat: 33.771, lng: 73.09};
        let loc = {lat: 24.7136, lng: 46.6753};

        if(this.initialLocation != null)
        {
            let coords = this.initialLocation.split(',');
            if (coords.length == 2)
            {
                loc.lat = +coords[0];
                loc.lng = +coords[1];
            }
        }

        this.currentLocation = loc;

        this.marker =
        {
            lat: this.currentLocation.lat,
            lng: this.currentLocation.lng,
            label: '',
            draggable: true
        }

        this.onAutoComplete();
    }

    onAutoComplete()
    {
        console.log('dhsjd')
        this._loader.load().then(() =>
        {
            const autoCompleteInput = new google.maps.places.Autocomplete(document.getElementById('autocompleteInput'), {});

            google.maps.event.addListener(autoCompleteInput, 'place_changed', () =>
            {
                this._zone.run(() =>
                {
                    const place = autoCompleteInput.getPlace();

                    this.currentLocation.lat = place.geometry.location.lat(),
                    this.currentLocation.lng = place.geometry.location.lng(),
                    this.formatted_address = place.formatted_address;

                    this.initialZoom = 16;
                    this.gMaps.panTo(this.currentLocation);

                    this.marker =
                    {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                        label: '',
                        draggable: true
                    };
                });
            });
        });
    }

    markerDragEnd(m, $event: any)
    {
        this.currentLocation.lat = $event.coords.lat;
        this.currentLocation.lng = $event.coords.lng;

        this.getLatLongDetail({
            lat: this.currentLocation.lat, lng: this.currentLocation.lng
        });

        this.gMaps.panTo(this.currentLocation);
    }

    mapClicked(e)
    {
    }

    clickedMarker(e)
    {
    }

    setLocation()
    {
        let loc = {latlng: this.currentLocation.lat + "," + this.currentLocation.lng, formatted_address: this.formatted_address};
        this.dialogRef.close(loc)
    }

    cancelLocation()
    {
        this.dialogRef.close(null)
    }

    getLatLongDetail(myLatlng) {

        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'latLng': myLatlng },
            (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                    if (results[0]) {

                        var address = "", city = "", state = "", zip = "", country = "", formattedAddress = "";
                        var lat;
                        var lng;

                        for (var i = 0; i < results[0].address_components.length; i++) {
                            var addr = results[0].address_components[i];
                            // check if this entry in address_components has a type of country
                            if (addr.types[0] == 'country')
                                country = addr.long_name;
                            else if (addr.types[0] == 'street_address') // address 1
                                address = address + addr.long_name;
                            else if (addr.types[0] == 'establishment')
                                address = address + addr.long_name;
                            else if (addr.types[0] == 'route')  // address 2
                                address = address + addr.long_name;
                            else if (addr.types[0] == 'postal_code')       // Zip
                                zip = addr.short_name;
                            else if (addr.types[0] == ['administrative_area_level_1'])       // State
                                state = addr.long_name;
                            else if (addr.types[0] == ['locality'])       // City
                                city = addr.long_name;
                        }


                        if (results[0].formatted_address != null) {
                            formattedAddress = results[0].formatted_address;
                        }

                        //debugger;

                        var location = results[0].geometry.location;

                        lat = location.lat;
                        lng = location.lng;

                        this.formatted_address = formattedAddress;

                        // alert('City: '+ city + '\n' + 'State: '+ state + '\n' + 'Zip: '+ zip + '\n' + 'Formatted Address: '+ formattedAddress + '\n' + 'Lat: '+ lat + '\n' + 'Lng: '+ lng);

                    }

                }
            });
    }

}
