import { Injectable } from '@angular/core';
// import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import { appConfig } from '../../config';
import { BaseLoaderService } from '../services/base-loader.service';

@Injectable()
export class MainService 
{
	MTCAdmin: any;
	headers: HttpHeaders;
    options: any;
    public auth_key: string;
	public baseUrl: string;
	appSelectorSubscription: Subscription;
	// user_app: any;
	
	constructor(private http: HttpClient, private router: Router, protected loaderService: BaseLoaderService) 
	{
		this.MTCAdmin = JSON.parse(localStorage.getItem('MTCAdmin'));

        this.baseUrl = appConfig.base_url;

		this.headers = new HttpHeaders({ 'access-token': this.MTCAdmin.auth_key});
		this.options = {headers: this.headers, observe: 'response'};
	}

	public getList(params: string): Promise<any>
    {
        return this.http.get(this.baseUrl + params, this.options)
        .toPromise().then((response: any) =>
        {
            if (response.status === 401 || response.status === 403) 
			{
				localStorage.clear();
				window.location.reload();
				// this.router.navigate(['auth/login']);
			} 
			else 
			{
				return response.body;
			}
        },
        (reason: any) =>
        {
			if (reason.error.status === 401 || reason.error.status === 403) 
			{
				localStorage.clear();
				window.location.reload();
				return reason;
			} 
			return reason;

        }).catch(this.handleError);
    }

	onLogout(): Promise<any>
	{
		return this.http.get(this.baseUrl + 'logout', this.options)
			.toPromise().then((response: any) =>
			{
				if (response.status === 401 || response.status === 403) 
				{
					localStorage.clear();
					window.location.reload();
				} 
				else 
				{
					return response.body;
				}
			},
			(reason: any) =>
			{
				if (reason.error.status === 401 || reason.error.status === 403) 
				{
					localStorage.clear();
					window.location.reload();
					return reason;
				} 
				return reason;
	
			}).catch(this.handleError);
	}

	postData( apiSlug: string, formData: any): Promise<any>
	{
		return this.http.post(this.baseUrl + apiSlug, formData, this.options)
			.toPromise().then((response: any) =>
			{
				if (response.status === 401 || response.status === 403) 
				{
					localStorage.clear();
					window.location.reload();
				} 
				else 
				{
					return response.body;
				}
			},
			(reason: any) =>
			{
				if (reason.error.status === 401 || reason.error.status === 403) 
				{
					localStorage.clear();
					window.location.reload();
					return reason;
				} 
				return reason;
	
			}).catch(this.handleError);
	}

	postFormData( apiSlug: string, formData: any): Promise<any>
	{
		return this.http.post(this.baseUrl + apiSlug, formData, this.options)
			.toPromise().then((response: any) =>
			{
				if (response.status === 401 || response.status === 403) 
				{
					localStorage.clear();
					window.location.reload();
				} 
				else 
				{
					return response.body;
				}
			},
			(reason: any) =>
			{
				if (reason.error.status === 401 || reason.error.status === 403) 
				{
					localStorage.clear();
					window.location.reload();
					return reason;
				} 
				return reason;
	
			}).catch(this.handleError);
	}

	public handleError(error: any): Promise<any>
    {
        return error;
    }

}
