import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MainService } from '../../services';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { appConfig } from 'src/config';
import { AlertDialog } from 'src/app/lib';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-drivers-form',
	templateUrl: './drivers-form.component.html'
})
export class DriversFormComponent implements OnInit, OnDestroy
{
	Form: FormGroup;
	isLoading: boolean;
	sub: Subscription;
	id: any;
	customer: any;
	isEditing: boolean;

	Countries: any[] = [];
	Cities: any[] = [];

	constructor(protected router: Router, protected _route: ActivatedRoute, protected mainApiService: MainService, protected fb: FormBuilder, protected dialog: MatDialog)
	{
		this.Form = this.fb.group({
			name: [null, [Validators.required]],
			phone: [null, [Validators.required]],
			// nationality: [null],
			iqama_no: [null, [Validators.required]],
			// country: [null],
			city_id: [null, [Validators.required]],
			email: [null, [Validators.required]],
			password: [null],
			role_type: ['driver'],
			current_role: ['admin'],
		})
	}


	ngOnInit()
	{
		this.Cities = JSON.parse(localStorage.getItem('Cities'));
		this.Countries = JSON.parse(localStorage.getItem('Countries'));

		this.Form.get('password').valueChanges.subscribe(response => {
			if(response.length != 0)
			{
				this.Form.get('password').setValidators([Validators.required]);
			}
			if (response.length == 0)
			{
				this.Form.get('password').setErrors(null);
			}
		});

		
		this.sub = this._route.params.subscribe(params => {
			this.id = params['id'];
			if (this.id != 'add')
			{
				this.isEditing = true;
				this.Form.addControl('id', new FormControl(this.id));
				this.onViewData();
				
			}
			else
			{
				this.isEditing = false;
			}
		});
	}

	onViewData(): void
	{
		let url = '/view-driver?id=' + this.id;
		this.mainApiService.getList(appConfig.base_url_slug + url).then(result => 
		{
			if (result.status === 200 && result.data) 
			{
				this.customer = result.data[0];
				this.Form.patchValue(this.customer);
				this.Form.get('iqama_no').setValue(this.customer.detail.iqama);
			}
			else 
			{
				
			}
		});
	}

	getValue(name)
    {
        return this.Form.get(name);
	}

	onLocationBack(): void
	{
		this.router.navigateByUrl('/main/drivers/list');
	}

	onSubmit(): void
	{
		let url = '';
		if(this.id == 'add')
		{
			url = '/register'
		}
		else
		{
			url = '/update-user';
		}
		this.isLoading = true;

		this.mainApiService.postData(appConfig.base_url_slug + url, this.Form.value).then(response => {
			if (response.status == 200 || response.status === 201)
			{
				this.isLoading = false;
				this.router.navigateByUrl('/main/drivers/list');
			}
			else
			{
				this.isLoading = false;
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Error';
				cm.message = response.error.error.message;
				cm.cancelButtonText = 'Ok';
				cm.type = 'error';
			}
		},
		Error => {
			this.isLoading = false;
			let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
			let cm = dialogRef.componentInstance;
			cm.heading = 'Error';
			cm.message = "Internal Server Error.";
			cm.cancelButtonText = 'Ok';
			cm.type = 'error';
		})
	}

	ngOnDestroy(): void
	{
		this.sub.unsubscribe();
	}
}
