export const appConfig =
{
    // Staging
    base_url: 'https://api-mtc.veroke.com/',
    file_url: 'https://api-mtc.veroke.com/uploads/',

    // Local
    // base_url: 'http://localhost:3000/',
    // file_url: 'http://localhost:3000/uploads/',

    base_url_slug: 'coordinator',

    default_auth_key: 'UP!and$',
    google_api_key: 'AIzaSyDykNkzGorhQSSUDKkJUzSYFMK04_YeHQ4'
}
