
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './guards/auth.guard';
import { MainAuthGuard } from './guards/main-auth.guard';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { AgularMaterialModule } from './material.module';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
// Services
import { AuthService, MainService, PaginationService, BaseLoaderService } from './services';

// Lib
// import { } from './lib';

// Config
// import { appConfig } from '../config';

// Directives
import { ClickOutsideDirective } from './directives/click-outside.directive';

// Page Components
import { LoginComponent } from './views/auth/login/login.component';
import { TemplateMainApp } from './templates/template-main-app/template-main-app.component';
import { ForgotPasswordComponent } from './views/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/auth/reset-password/reset-password.component';
import { OrdersListComponent, OrdersFormComponent, AssignOrderComponent } from './views/orders';
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { CustomersListComponent, CustomersFormComponent } from './views/customers';
import { DriversListComponent, DriversFormComponent } from './views/drivers';
import { AssignVehicleComponent, VehiclesFormComponent, VehiclesListComponent } from './views/vehicles';
import { appConfig } from 'src/config';

import { LibModule } from './lib/module';
import { ManageAddress } from './views/address/manage-address.component';
import { AddAddress } from './views/address/add-address.component';
import { ContractsFormComponent } from './views/contracts/contracts_form.component';
import { ContractsListComponent } from './views/contracts/contracts_list.component';
import { ContractOnDemandComponent } from './views/contracts/contract-ondemand.component';
import { TrackOrdersComponent } from './views/orders/track-orders.component';
import { EditContractsComponent } from './views/contracts/edit_contract.component';
import { AllTrackOrdersComponent } from './views/orders/all_orders_tracking.component';
import { OrderDetailComponent } from './views/orders/order_detail.component';
import { VehicleDetailComponent } from './views/vehicles/vehicle_detail.component';


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        // HttpModule,
        // TagInputModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        AgularMaterialModule,
        AgmCoreModule.forRoot({
            apiKey: appConfig.google_api_key,
            libraries: ['places']
        }),
        FlexLayoutModule,
        MalihuScrollbarModule.forRoot(),
        LibModule,
        // NgxEditorModule,
        // NgxMaskModule.forRoot(),
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        // NgProgressModule,
    ],
    providers: [
        AuthGuard,
        AuthService,
        MainService,
        MainAuthGuard,
        PaginationService,
        BaseLoaderService,
        GoogleMapsAPIWrapper,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        // { provide: OWL_DATE_TIME_FORMATS, useValue: 'fr' },
        // {provide: HTTP_INTERCEPTORS, useClass: NgProgressInterceptor, multi: true },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: ApiLoaderInterceptorService,
        //     multi: true
        // },
        // ApiLoaderService
    ],
    declarations: [

        AppComponent,
        LoginComponent,
        TemplateMainApp,
        ClickOutsideDirective,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        OrdersListComponent, OrdersFormComponent,
        DashboardComponent,

        //My
        CustomersListComponent, CustomersFormComponent,

        DriversListComponent, DriversFormComponent,

        AssignVehicleComponent, VehiclesFormComponent, VehiclesListComponent,
        AssignOrderComponent, TrackOrdersComponent,
        ManageAddress,
        AddAddress,
        ContractsListComponent,
        ContractsFormComponent,
        ContractOnDemandComponent,
        EditContractsComponent,
        AllTrackOrdersComponent,
        OrderDetailComponent,
        VehicleDetailComponent
    ],
    entryComponents: [
        AssignOrderComponent,
        OrderDetailComponent,
        AssignVehicleComponent,
        VehicleDetailComponent
        // AlertDialog,
        // LocationViewComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }